import React from 'react';

import FormBanner from './form-banner'
import ApplyStep1 from './apply-step-1';
import ApplyStep2 from './apply-step-2';
import FormThankYou from './form-thank-you';
import { StaticImage } from 'gatsby-plugin-image';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/main.css';
import '../../css/main.scss';
import '../../css/hire-apply.css';

export default class Apply extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef()

        this._next = this._next.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.submitPostgresql = this.submitPostgresql.bind(this)
        this.state = { currentStep: 1 }

        this.store = {
            fname: '',
            lname: '',
            phone: '',
            email: '',
            linkedin: '',
        };
    }

    submitPostgresql() {
        this.setState({ 'postgresql': 'called' },
            () => {
                const formFields = this.mapFields()
                formFields.segment_id = 7
                fetch('/.netlify/functions/postgresqlSubmission', {
                    method: 'POST',
                    headers: { 'Content-Type': 'text/html; charset=utf-8' },
                    body: JSON.stringify({
                        'formName': 'apply',
                        formFields,
                    }),
                }).catch((error) => alert(error))
            }
        )
    }

    mapFields = () => {
        let fields = {}

        fields.firstname = this.store.fname
        fields.lastname = this.store.lname
        fields.phone = this.store.phone
        fields.email = this.store.email
        fields.linkedin_url = this.store.linkedin
        fields.fullname = `${this.store.fname} ${this.store.lname}`.trim()

        const obj = this.store
        for (let key in obj) {
            if (!'fname lname phone email linkedin'.match(key)) {
                fields[key] = obj[key]
            }
        }
        return fields
    }

    _next() {
        let isValid = this.form.current.reportValidity();
        if (isValid) {
            if (this.state.currentStep === 2) {
                let fullname = `${this.store.fname} ${this.store.lname}`.trim()
                this.store['fullname'] = fullname
                this.submitPostgresql()
            }
            this.setState({ currentStep: this.state.currentStep + 1 })
        }
    }

    get nextButton() {
        let currentStep = this.state.currentStep;
        if (currentStep < 3) {
            return (
                <div className="register__actions">
                    <button onClick={this._next}
                        className="register__btn-next btn py-3 u-font-alt-2 u-weight-smb ml-3">
                        NEXT
                  <StaticImage src='../../images/i-arrow-right.png'
                            alt='Arrow pointing to the right'
                            className='ml-5 btn__arrow' />
                    </button>
                </div>
            )
        }
        return null
    }

    handleChange = (event) => {
        this.store[event.target.id] = event.target.value
    }

    handleClick = (nextValue, prevValue, name) => {
        this.store[name] = nextValue
    }

    render() {
        return (
            <React.Fragment>
                <FormBanner bannerstep={this.state.bannerstep}
                    title="Apply as a Freelancer"
                    subtitle="Sign up and be notified when an available job matches your skill set. "
                    step1label="Contact Info"
                    step2label="Skills Offered"
                    step3label="Submit"
                />
                <section className="register">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="register__box">
                                    <div className="box p-5">
                                        <div className="register__main">
                                            <div className="register__step cx_starbox">
                                                <form ref={this.form} className="form register__step-main" onSubmit={(e) => e.preventDefault()} >
                                                    <ApplyStep1 currentStep={this.state.currentStep} handleChange={this.handleChange} />
                                                    <ApplyStep2 currentStep={this.state.currentStep} handleClick={this.handleClick} />
                                                    <FormThankYou currentStep={this.state.currentStep} />
                                                    {this.nextButton}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
