import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/main.css'
import '../../css/main.scss'
import '../../css/hire-apply.css'

export default class ApplyStep1 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fname: '',
            lname: '',
            phone: '',
            email: '',
            linkedin: '',
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value })
        this.props.handleChange(event)
    }

    render() {

        if (this.props.currentStep !== 1) {
            return null
        }
        return (
            <React.Fragment>
                <div className="register__step-intro">
                    <h4>Step 1 of 3</h4>
                    <h2>Contact Info</h2>
                    <p className="p--md u-font-alt-2">Get Connected &amp; Expand Your Network. Build Your Network Of Contacts.</p>
                </div>
                <div className="form register__step-main">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <input id='fname'
                                name='fname'
                                type="text"
                                placeholder="First Name *"
                                value={this.state.fname}
                                onChange={this.handleChange}
                                required />
                        </div>
                        <div className="col-12 col-md-6">
                            <input id='lname'
                                name='lname'
                                type="text"
                                placeholder="Last Name *"
                                value={this.state.lname}
                                onChange={this.handleChange}
                                required />
                        </div>
                        <div className="col-12 col-md-6">
                            <input id='email'
                                name='email'
                                type="email"
                                placeholder="Your Email *"
                                value={this.state.email}
                                onChange={this.handleChange}
                                required />
                        </div>
                        <div className="col-12 col-md-6">
                            <input id='phone'
                                name='phone'
                                type="tel"
                                placeholder="Your Phone *"
                                value={this.state.phone}
                                onChange={this.handleChange}
                                required />
                        </div>
                        <div className="col-12">
                            <input id='linkedin'
                                name='linkedin'
                                type="url"
                                placeholder="Linkedin Profile"
                                value={this.state.linkedin}
                                onChange={this.handleChange} />
                        </div>
                        <div className="col-12">
                            <div className="checkbox">
                                <i><FontAwesomeIcon icon={faCheck} /></i>
                                <input type="checkbox" id="t&amp;c" required onBlur={this.setValue} />
                                <label htmlFor="t&amp;c">Please accept 
                                    <span className="u-color-secondary">
                                        <a href="/terms-and-conditions" target="_blank" > terms and conditions</a>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
