import React from 'react'
import StarRatingComponent from 'react-star-rating-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SkillsObjArray from '../../data/skills.json'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/main.css'
import '../../css/main.scss'
import '../../css/hire-apply.css'

const noPadding = {
    padding: 0
}

//local component
const StaredSkill = (props) => {
    if (!props.skill.display) {
        return null
    }
    return (
        <React.Fragment>
            <div key={props.index} className="col-md-4 col-sm-6">
                <div className="cx_skills">
                    <h3 style={noPadding}>{props.skill.label}</h3>
                    <div className="spanstar">
                        <div style={{ fontSize: 38 }}>
                            <StarRatingComponent
                                name={props.skill.name}
                                onStarClick={props.onClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default class ApplyStep2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            skills: SkillsObjArray,
            searchState: false
        }
        this.change = this.props.handleChange
        this.onClick = this.props.handleClick
    }

    searchValueHasBeenEntered = value => (value.length > 0)

    search = event => {
        event.preventDefault()
        let matchingSkills = []
        let value = event.target.value.toLowerCase()

        if (this.searchValueHasBeenEntered(value)) {
            matchingSkills = this.state.skills.filter(skill => {
                return (!skill.display && skill.label.toLowerCase().match(value))
            })
            if (matchingSkills.length === 1 && event.target.value.length > 1) {
                const idx = this.state.skills.findIndex(x => x.name === matchingSkills[0].name)
                let skillsArray = this.state.skills
                skillsArray[idx].display = true
                this.setState({ skills: skillsArray })
            }
            if (matchingSkills.length === 0) {
                this.setState({ searchState: true })
            } else {
                this.setState({ searchState: false })
            }
        }
    }

    render() {
        if (this.props.currentStep !== 2) {
            return null
        }
        let searchMessage = this.state.searchState ? 'Value not supported' : ''
        return (
            <div className="register__step">
                <div className="form register__step-main">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="cx_starbox">
                                <div className="register__step-intro"><h4>Step 2 of 3</h4>
                                    <h2>Skills Offered</h2>
                                    <p className="p--md u-font-alt-2">Get Connected &amp; Expand Your Network. Build Your Network Of Contacts..</p>
                                </div>
                                <div className="row">
                                    {this.state.skills.map((skill, i) =>
                                        <StaredSkill key={i} skill={skill} onClick={this.onClick} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="cx_starbox">
                                <div className="row">
                                    <div className="col-md-4 col-sm-6" style={noPadding}>
                                        <div className="cx_skills" style={{ marginBottom: '0px' }}>
                                            <h3 style={noPadding}>Add another skill</h3>
                                            <div>
                                                <div style={{ fontSize: '1.5em' }}>
                                                    <input type="search" id="search-bar"
                                                        placeholder="search skill list"
                                                        onChange={this.search} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1 col-sm-1" style={noPadding}>
                                        <div className="cx_skills">
                                            <h3 style={noPadding}>&nbsp;</h3>
                                            <div className="spanstar">
                                                <div style={{ fontSize: '2em' }}>
                                                    <FontAwesomeIcon style={{ float: 'left' }} icon={faSearch} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-2" style={noPadding}>
                                        <div className="cx_skills">
                                            <h3 style={noPadding}>&nbsp;</h3>
                                        </div>
                                        <div style={{ color: 'red' }}>{searchMessage}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button style={{ visibility: 'hidden' }}>Prevent enter key submission</button>
                    </div>
                </div >
            </div>
        )
    }
}
