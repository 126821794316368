import React from 'react'
import Layout from '../components/layout'
import Apply from '../components/forms/apply'
import Seo from '../components/seo'

const ApplyPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Apply />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Apply to be Featured in our Freelancer Directory.'
  const description =
    'Apply to be featured in the Code Staff Freelancer Directory. Get matched up with jobs that fit your qualifications. '

  return <Seo type="page" title={title} description={description} />
}

export default ApplyPage
